<template>
  <v-sheet
    class="devtools-warnings !fixed top-[118px] bottom-0 w-[25vw] min-w-[300px] !z-10 -right-[max(25vw,300px)] !transition-all"
    :class="{ '!right-0': showPanel }"
    color="warning"
  >
    <v-fab
      v-show="showFAB"
      icon
      color="warning"
      class="!absolute top-8 -left-16 z-10"
      @click="togglePanel"
    >
      <v-badge color="error" :content="warnings.length">
        <v-icon
          v-if="showPanel"
          key="collapse"
          icon="mdi-chevron-right"
          size="x-large"
        />
        <v-icon
          v-else
          key="alert"
          icon="mdi-alert"
          class="!w-8 !h-8"
        />
      </v-badge>
    </v-fab>
    <div class="fs-18 fw-medium pt-2 px-4 sticky top-0">
      Schema Warnings
    </div>
    <div class="overflow-y-auto px-4 max-h-[calc(100%-2rem)]">
      <div
        v-for="warning of warnings"
        :key="warning.id"
        class="my-2 fs-14"
      >
        <v-row
          align="center"
          @click="toggleRow(warning.id)"
        >
          <v-col cols="11">
            <p class="font-weight-bold">
              <v-chip
                class="mr-1 px-3"
                color="error"
                variant="elevated"
                size="x-small"
                v-text="warning.type.toUpperCase()"
              />
              <span v-text="warning.title" />
            </p>
            <p v-if="warning.description" v-text="warning.description" />
          </v-col>
          <v-col cols="1">
            <v-btn
              v-if="warning.context"
              size="sm"
              variant="text"
              :icon="rows[warning.id] ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            />
          </v-col>
        </v-row>
        <code
          v-if="warning.context && rows[warning.id]"
          class="block bg-gray-600 w-100% mt-2 pa-2 text-white whitespace-pre overflow-scroll"
          v-text="warning.context"
        />
        <v-divider class="mt-2" />
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import { computed, defineComponent, inject, reactive, ref, watch } from 'vue'

import { CONTROLLER_KEY } from '@/plugins/devtools/shared'

export default defineComponent({
  name: 'DevtoolsWarnings',

  setup () {
    const controller = inject(CONTROLLER_KEY)!
    const warnings = computed(() => controller.currentConfigWarnings ?? [])

    const showFAB = computed(() => warnings.value.length > 0)
    watch(showFAB, () => {
      showPanel.value = false
    })

    const showPanel = ref(false)
    function togglePanel (): void {
      showPanel.value = !showPanel.value
    }

    const rows = reactive<Record<string, boolean>>({})
    function toggleRow (id: string): void {
      rows[id] = !rows[id]
    }

    return {
      warnings,
      showFAB,
      showPanel,
      togglePanel,
      rows,
      toggleRow,
    }
  },
})
</script>
